import { StatusBar, Style } from '@capacitor/status-bar'

const helper = {

    setStatusBarStyle(mode, devicePlatform) {
        if (devicePlatform != 'web') {
            if (mode == 'dark') { StatusBar.setStyle({ style: Style.Dark }) }
            if (mode == 'light') { StatusBar.setStyle({ style: Style.Light }) }
        }
    },
    
    getHumanMinuteTime(minutes) {
        minutes = Math.round(minutes)
        return parseInt(String((minutes / (60)) % 60)) + ":" +
        this.padZero(parseInt(String((minutes) % 60)))
    },
    
    padZero(value) {
        return (value < 10) ? "0" + value : value
    },

    async getSignature(type, deviceId, apiSecretKey, email, accessToken, bookId) {
        let data = ''
        const timeStamp = Math.floor(Date.now() / 1000)
        if (type === 'basic') {
            data = deviceId + timeStamp + apiSecretKey
        } else if (type === 'access-token') {
            data = deviceId + timeStamp + email + apiSecretKey
        } else if (type === 'purchased-books') {
            data = deviceId + timeStamp + email + accessToken + apiSecretKey
        } else if (type === 'track-file') {
            data = deviceId + timeStamp + email + accessToken + bookId + apiSecretKey
        }
        //Zdroj kde som nasiel riesenie: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
        const msgUint8 = new TextEncoder().encode(data)
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const signature = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return signature
    },

    getHeardStatusFromTrack(booksLocalData, bookId, trackNumber){
        const bookIndex = booksLocalData.findIndex(book => book.id == bookId)
        if (bookIndex < 0) { return false }
        const tracks = booksLocalData[bookIndex].tracks
        const trackIndex = tracks.findIndex(track => track.number == trackNumber)
        if (trackIndex < 0) { return false }
        return tracks[trackIndex].heard
    },

    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

}

export default helper