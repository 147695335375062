import { Preferences } from '@capacitor/preferences';

export default {
    setUser(state, user) {
        state.email = user.email
        state.status = user.status
        state.accessToken = user.accessToken
        Preferences.set({
            key: 'user',
            value: JSON.stringify(user)
        })
    }
}