export default {
    email(state) {
        return state.email
    },
    status(state) {
        return state.status
    },
    accessToken(state) {
        return state.accessToken
    }
}