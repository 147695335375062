<template>
  <ion-page>
    <ion-content v-if="isLoading" class="load">
      <ion-img src="./assets/wisteriabooks_logo.png"></ion-img>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonPage, IonContent, IonImg } from '@ionic/vue';

export default defineComponent({
  name: 'SplashScreen',
  components: {
    IonPage, 
    IonContent,
    IonImg
  },
  data() {
    return {
      isLoading: false
    }
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch('setDeviceId')
    await this.$store.dispatch('setDevicePlatform')
    await this.$store.dispatch('user/getUserFromStorage')
    await this.$store.dispatch('purchasedBooks/getBooksLocalDataFromStorage')
    await this.$store.dispatch('purchasedBooks/fetchBooksFromApi')
    this.isLoading = false
    this.$router.replace('/tabs/books')
  }
});
</script>

<style scoped>
ion-content.load {
    text-align: center;
    --color: gray;
    --padding-top: 150px
}

ion-img {
  height: 100px;
} 
</style>
