export default {
    setBooks(state, payload) {
        state.books = payload
    },
    setBooksLocalData(state, payload) {
        state.booksLocalData = payload
    },
    setBookAsHeard(state, payload) {
        const bookId = payload.bookId
        const trackNumber = payload.trackNumber
        const bookIndex = state.books.findIndex(book => book.id == bookId)
        const book = state.books[bookIndex]
        const trackIndex = book.tracks.findIndex(track => track.number == trackNumber)
        state.books[bookIndex].tracks[trackIndex].heard = true
    }
}