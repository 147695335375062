import axios from 'axios';
import helper from '@/helper.js'

export default {
    async fetchBooksFromApi(context) {
        const books = []
        const deviceId = context.rootGetters['deviceId']
        const apiUrl = context.rootGetters['apiUrl']
        const apiSecretKey = context.rootGetters['apiSecretKey']
        const timeStamp = Math.floor(Date.now() / 1000)
        const signature = await helper.getSignature('basic', deviceId, apiSecretKey)
        const response = await axios.get(apiUrl + '/audiobooks?version=' + deviceId + '&timestamp=' + timeStamp.toString() + '&signature=' + signature)
        //console.log(response)
        response.data.forEach((book) => {
            const newBook = {
                id: book.node_id,
                title: book.title,
                description: book.description,
                price: book.price,
                detailUrl: book.detailUrl,
                comingSoon: book.comingSoon,
                duration: book.duration,
                bgColor: book.color,
                languageCode: book.languageCode,
                coverImageUrl: book.coverImageUrl,
                sampleAudioFileUrl: book.sampleAudioFileUrl,
                tags: book.tags,
                authors: book.authors,
                narrators: book.narrators,
                publishers: book.publishers,
                tracks: book.tracks
            }
            if (!newBook.comingSoon) {
                books.push(newBook)
            }
        })
        context.commit('setBooks', books)
    }
}