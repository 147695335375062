<template>

  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/tabs/books">
          <ion-icon :icon="albumsOutline" />
          <ion-label>Prechádzať</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="tab2" href="/tabs/purchased-books">
          <ion-icon :icon="bookOutline" />
          <ion-label>Moja knižnica</ion-label>
        </ion-tab-button>
        
        <ion-tab-button tab="tab3" href="/tabs/profile">
          <ion-icon :icon="personOutline" />
          <ion-label>Profil</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>

</template>

<script>
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { albumsOutline, bookOutline, personOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  data() {
    return {
      albumsOutline,
      bookOutline, 
      personOutline
    }
  }
});
</script>
