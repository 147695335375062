import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import helper from '@/helper.js'

export default {
    async fetchBooksFromApi(context) {
        const books = []
        const status = context.rootGetters['user/status']
        if (status == 'verified') {
            const booksLocalData = context.getters.booksLocalData
            const deviceId = context.rootGetters['deviceId']
            const apiUrl = context.rootGetters['apiUrl']
            const apiSecretKey = context.rootGetters['apiSecretKey']
            const timeStamp = Math.floor(Date.now() / 1000)
            const email = context.rootGetters['user/email']
            const accessToken = context.rootGetters['user/accessToken']
            const signature = await helper.getSignature('purchased-books', deviceId, apiSecretKey, email, accessToken)
            const response = await axios.get(apiUrl + '/purchased-audiobooks?mail=' + email + '&access_token=' + accessToken + '&version=' + deviceId + '&timestamp=' + timeStamp.toString() + '&signature=' + signature)
            //console.log(response)
            response.data.forEach((book) => {
                const tracks = []
                book.tracks.forEach((track) => {
                    const heard = helper.getHeardStatusFromTrack(booksLocalData, book.node_id, track.number)
                    const newTrack = {
                        name: track.name,
                        number: track.number,
                        length: track.length,
                        file: track.file,
                        audioFilePath: track.audioFilePath,
                        audioFileUrl: track.audioFileUrl,
                        heard: heard
                    }
                    tracks.push(newTrack)
                })
                const newBook = {
                    id: book.node_id,
                    title: book.title,
                    description: book.description,
                    price: book.price,
                    detailUrl: book.detailUrl,
                    comingSoon: book.comingSoon,
                    duration: book.duration,
                    bgColor: book.color,
                    languageCode: book.languageCode,
                    coverImageUrl: book.coverImageUrl,
                    sampleAudioFileUrl: book.sampleAudioFileUrl,
                    tags: book.tags,
                    authors: book.authors,
                    narrators: book.narrators,
                    publishers: book.publishers,
                    tracks: tracks
                }
                books.push(newBook)
            })
            context.commit('setBooks', books)
        }
    },
    async getBooksLocalDataFromStorage(context) {
        const response = await Preferences.get({ key: 'booksLocalData' })
        if (response.value) {
            const booksLocalData = JSON.parse(response.value)
            context.commit('setBooksLocalData', booksLocalData)
        }
    },
    setHeardTrack(context, payload) {
        const bookId = payload.bookId
        const trackNumber = payload.trackNumber
        const action = payload.action
        const booksLocalData = context.getters['booksLocalData']
        const bookIndex = booksLocalData.findIndex(book => book.id == bookId)
        if (bookIndex >= 0) {
            const tracks = booksLocalData[bookIndex].tracks
            const trackIndex = tracks.findIndex(track => track.number == trackNumber)
            if (trackIndex >= 0) {
                if (action === 'set') {
                    booksLocalData[bookIndex].tracks[trackIndex].heard = true
                } else if (action === 'toggle') {
                    if (booksLocalData[bookIndex].tracks[trackIndex].heard == true) {
                        booksLocalData[bookIndex].tracks[trackIndex].heard = false
                    } else {
                        booksLocalData[bookIndex].tracks[trackIndex].heard = true
                    }
                }
            } else {
                booksLocalData[bookIndex].tracks.push({ number: trackNumber, heard: true })
            }
        } else {
            const tracks = []
            tracks.push({ number: trackNumber, heard: true })
            booksLocalData.push({ id: bookId, tracks: tracks })
        }
        Preferences.set({ key: 'booksLocalData', value: JSON.stringify(booksLocalData) })
        if (action === 'set') {
            context.commit('setBookAsHeard', { bookId: bookId, trackNumber: trackNumber })
        }
        context.commit('setBooksLocalData', booksLocalData)
    }
}