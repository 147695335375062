import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import helper from '@/helper.js'

export default {
    async sendAccessToken(context, email) {
        const deviceId = context.rootGetters['deviceId']
        const apiUrl = context.rootGetters['apiUrl']
        const apiSecretKey = context.rootGetters['apiSecretKey']
        const timeStamp = Math.floor(Date.now() / 1000)
        const signature = await helper.getSignature('access-token', deviceId, apiSecretKey, email)
        const response = await axios.get(apiUrl + '/user-auth?mail=' + email + '&access_token=&version=' + deviceId + '&timestamp=' + timeStamp.toString() + '&signature=' + signature)
        //console.log(response)
        if (response.data.auth == 'access_token_sent') {
            context.commit('setUser', {
                email: email,
                status: 'sent-token',
                accessToken: ''
            })
        } else if (response.data.auth == 'mail_not_exists') {
            const error = new Error('email-not-exists')
            throw error
        }
    },
    async verifyAccessToken(context, payload) {
        const deviceId = context.rootGetters['deviceId']
        const apiUrl = context.rootGetters['apiUrl']
        const apiSecretKey = context.rootGetters['apiSecretKey']
        const timeStamp = Math.floor(Date.now() / 1000)
        const signature = await helper.getSignature('access-token', deviceId, apiSecretKey, payload.email, payload.accessToken)
        const response = await axios.get(apiUrl + '/user-auth?mail=' + payload.email + '&access_token=' + payload.accessToken + '&version=' + deviceId + '&timestamp=' + timeStamp.toString() + '&signature=' + signature)
        //console.log(response)
        if (response.data.auth == 'success') {
            context.commit('setUser', {
                email: payload.email,
                status: 'verified',
                accessToken: payload.accessToken
            })
        } else if (response.data.auth == 'wrong_access_token') {
            const error = new Error('wrong-access-token')
            throw error
        }
    },
    async getUserFromStorage(context) {
        const response = await Preferences.get({ key: 'user' })
        if (response.value) {
            const user = JSON.parse(response.value)
            context.commit('setUser', user)
        }
    },
    deleteUser(context) {
        context.commit('setUser', {
            email: '',
            status: '',
            accessToken: ''
        })
    }
}