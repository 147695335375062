import { createStore } from 'vuex'
import { Device } from '@capacitor/device'

import booksModule from '@/store/books'
import purchasedBooksModule from '@/store/purchased-books'
import userModule from '@/store/user'

const store = createStore({
    modules: {
        books: booksModule,
        purchasedBooks: purchasedBooksModule,
        user: userModule
    },
    state() {
        return {
            deviceId: '',
            devicePlatform: '',
            apiUrl: 'https://wisteriabooks.sk/app',
            apiSecretKey: 'cc44A%6&&rJh?4K@2XhK4#yM%Nn$pF2u'
        }
    },
    getters: {
        deviceId(state) {
            return state.deviceId
        },
        devicePlatform(state) {
            return state.devicePlatform
        },
        apiUrl(state) {
            return state.apiUrl
        },
        apiSecretKey(state) {
            return state.apiSecretKey
        }
    },
    mutations: {
        setDeviceId(state, payload) {
            state.deviceId = payload
        },
        setDevicePlatform(state, payload) {
            state.devicePlatform = payload
        }
    },
    actions: {
        async setDeviceId(context) {
            const response = await Device.getId()
            context.commit('setDeviceId', response.uuid)
        },
        async setDevicePlatform(context) {
            const response = await Device.getInfo()
            context.commit('setDevicePlatform', response.platform)
        }
    }
})

export default store